import React, {useContext, useState} from 'react';
import {Headline} from "../components/headline";
import {Slide} from 'react-slideshow-image';
import {ResizeContext} from "../context/resize-context";
import {useTranslation} from "react-i18next";
import {getResponsiveSettings} from "../utils";
import chevron from '../assets/chevron-white.svg'
import forbes from '../assets/forbes.jpg'
import elle from '../assets/elle.jpg'
import lofficiel from '../assets/lofficiel.jpg'
import 'react-slideshow-image/dist/styles.css';

export const Press = () => {
    const {t} = useTranslation();
    const {width, isMobile} = useContext(ResizeContext);
    const [activeIndex, setActiveIndex] = useState(0);
    const articles = [
        {
            image: lofficiel,
            title: t('press.articles.lofficiel.title'),
            description: t('press.articles.lofficiel.description'),
            date: t('press.articles.lofficiel.date'),
        },
        {
            image: elle,
            title: t('press.articles.elle.title'),
            description: t('press.articles.elle.description'),
            date: t('press.articles.elle.date'),
        },
        {
            image: forbes,
            title: t('press.articles.forbes.title'),
            description: t('press.articles.forbes.description'),
            date: t('press.articles.forbes.date'),
        },
    ];
    const articlesList = width > articles.length * 480 ? [...articles, ...articles] : articles;
    const mobileArticlesList = articles.length >= 3 ? [...articles].slice(activeIndex, activeIndex + 3) : articles;

    const customArrowPreviewComponent = <div className='slider-navigation'><img src={chevron} alt="chevron"/></div>;

    const updateList = () => {
        if(activeIndex + 3 <= articles.length - 1){
            setActiveIndex(activeIndex + 3);
        } else {
            setActiveIndex(0);
        }
    }

    const properties = {
        prevArrow: customArrowPreviewComponent,
        nextArrow: customArrowPreviewComponent,
    }
    return (
        <>
            <Headline langKey={'press.title'} anchor='press'/>
            <section className="press">
                {isMobile ? (
                    <>
                        {mobileArticlesList.map(({title, description, image, date}, index) => (
                            <div className='press-article' key={index}>
                                <img
                                    src={image}
                                    alt={`article-${index + 1}`}
                                />
                                <div className="press-article-text-content col">
                                    <h2 className="press-article-title">{title}</h2>
                                    <p className="press-article-description">{description}</p>
                                    <p className="press-article-date">{date}</p>
                                </div>
                            </div>
                        ))}
                        <div className="button-wrapper">
                            <button onClick={updateList}>{t('global.show_more')}</button>
                        </div>
                    </>
                ) : (
                    <>
                        <Slide autoplay={false} slidesToScroll={2} slidesToShow={2} indicators={false} responsive={getResponsiveSettings(480)} {...properties}>
                            {articlesList.map(({title, description, image, date}, index) => (
                                <div className='press-article' key={index}>
                                    <img
                                        src={image}
                                        alt={`article-${index + 1}`}
                                    />
                                    <div className="press-article-text-content col">
                                        <h2 className="press-article-title">{title}</h2>
                                        <p className="press-article-description">{description}</p>
                                        <p className="press-article-date">{date}</p>
                                    </div>
                                </div>
                            ))}
                        </Slide>
                    </>
                )}
            </section>
        </>
    );
};
