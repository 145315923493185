import React, {useContext} from "react";
import {Header} from "./header";
import {Footer} from "./footer";
import {ModeContext, ModeEnum} from "../context/mode-context";
import {useTranslation} from "react-i18next";

export const Page = ({ children }) => {
    const {mode} = useContext(ModeContext);
    const { t } = useTranslation();
    const footerText = mode === ModeEnum.Club
        ? t('main.club.footer_text')
        : t('main.clinic.footer_text') ;
    return (
        <>
            <Header />
            <main className="main">
                {children}
            </main>
            <Footer text={footerText}/>
        </>
    )
};