import React, {useContext} from 'react';
import {Headline} from "../components/headline";
import {Partners} from "../components/partners";
import {Slide} from 'react-slideshow-image';
import {ResizeContext} from "../context/resize-context";
import {useTranslation} from "react-i18next";
import chevron from '../assets/chevron-white.svg'
import insta1 from '../assets/insta_1.jpg';
import insta2 from '../assets/insta_2.jpg';
import insta3 from '../assets/insta_3.jpg';
import instagramLogo from "../assets/instagram_logo_green.svg";
import 'react-slideshow-image/dist/styles.css';

export const Instagram = () => {
    const {t} = useTranslation();
    const { isMobile} = useContext(ResizeContext);
    const posts = [insta1, insta2, insta3];

    const customArrowPreviewComponent = <div className='slider-navigation'><img src={chevron} alt="chevron"/></div>;

    const properties = {
        prevArrow: customArrowPreviewComponent,
        nextArrow: customArrowPreviewComponent,
    }
    return (
        <>
            <Headline langKey={'instagram.title'} anchor='instagram'/>
            <section className="instagram">
                {isMobile ? (
                    <>
                        {posts.map((post, index) => (
                            <div className='instagram-post' key={index}>
                                <img
                                    src={post}
                                    alt={`article-${index + 1}`}
                                />
                            </div>
                        ))}
                        <div className="instagram-link-wrapper row">
                            <a  className='insta_link' href="https://www.instagram.com/leobeautyclub.warsaw/" target="_blank" rel="noreferrer">
                                <img src={instagramLogo} alt="instagram-logo"/>
                                <span>{t('main.club.insta_link')}</span>
                            </a>
                        </div>
                        <Partners/>
                    </>
                ) : (
                    <>
                        <Slide autoplay={false} slidesToScroll={1} slidesToShow={3} indicators={false} {...properties}>
                            {posts.map((post, index) => (
                                <div className='instagram-post' key={index}>
                                    <img
                                        src={post}
                                        alt={`article-${index + 1}`}
                                    />
                                </div>
                            ))}
                        </Slide>
                        <div className="instagram-link-wrapper row">
                            <a  className='insta_link' href="https://www.instagram.com/leobeautyclub.warsaw/" target="_blank" rel="noreferrer">
                                <img src={instagramLogo} alt="instagram-logo"/>
                                <span>{t('main.club.insta_link')}</span>
                            </a>
                        </div>
                        <Partners/>
                    </>
                )}
            </section>
        </>
    );
};
