import React, {memo} from 'react';
import {AboutUs} from "../../sections/about-us";
import {Services} from "../../sections/services";
import {PriceList} from "../../sections/price-list";
import {OurWorks} from "../../sections/our-works";
import {OurTeam} from "../../sections/our-team";
import {Press} from "../../sections/press";
import {Instagram} from "../../sections/instagram";
import {Addresses} from "../../sections/addresses";
import {Page} from "../../components/page";

export const Clinic = memo(() => (
    <Page>
        <AboutUs/>
        <Services/>
        <PriceList/>
        <OurWorks/>
        <OurTeam/>
        <Press/>
        <Instagram/>
        <Addresses/>
    </Page>
))