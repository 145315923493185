import {useEffect, useState} from "react";
import {DEFAULT_MUTATION_OPTIONS} from "./constants";
import {debounce} from "./utils";

export const useMutationObservable = (targetEl, cb, options = DEFAULT_MUTATION_OPTIONS) => {
    const [observer, setObserver] = useState(null);

    useEffect(() => {
        const { debounceTime } = options;
        const obs = new MutationObserver(
            debounceTime > 0 ? debounce(cb, debounceTime) : cb
        );
        setObserver(obs);
    }, [cb, options, setObserver]);

    useEffect(() => {
        if (!observer || !targetEl) return;

        const { config } = options;

        try {
            observer.observe(targetEl, config);
        } catch (e) {
            console.error(e);
        }

        return () => {
            observer && observer.disconnect();
        };
    }, [observer, targetEl, options]);
}

export function useIntersectObserve(
    elementRef,
    options = {
        threshold: 0,
        root: null,
        rootMargin: '0%',
        freezeOnceVisible: false,
    }
){
    const [entry, setEntry] = useState();

    const frozen = entry?.isIntersecting && options.freezeOnceVisible

    const updateEntry = ([entry]) => {
        setEntry(entry)
    }
    const normalizedTrashHold = JSON.stringify(options.threshold);

    useEffect(() => {
        const node = elementRef?.current
        const hasIOSupport = !!window.IntersectionObserver

        if (!hasIOSupport || frozen || !node) return

        const observer = new IntersectionObserver(updateEntry, options)

        observer.observe(node)

        return () => observer.disconnect()

    }, [elementRef, normalizedTrashHold, options.root, options.rootMargin, frozen, options])

    return entry
}