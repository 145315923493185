import React, {useCallback, useContext, useState} from 'react';
import {Headline} from "../components/headline";
import {Slide} from 'react-slideshow-image';
import {ResizeContext} from "../context/resize-context";
import {useTranslation} from "react-i18next";
import {getResponsiveSettings} from "../utils";

import ImageViewer from 'react-simple-image-viewer';
import chevron from '../assets/chevron-white.svg'
import work1 from '../assets/works_1.jpg'
import work2 from '../assets/works_2.jpg'
import work3 from '../assets/works_3.jpg'
import 'react-slideshow-image/dist/styles.css';

export const OurWorks = () => {
    const {t} = useTranslation();
    const {width, isMobile} = useContext(ResizeContext);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const works = [work1, work2, work3];
    const images = width > works.length * 480 ? [...works, ...works] : works;
    const mobileWorksList = works.length >= 3 ? [...works].slice(activeIndex, activeIndex + 3) : works;

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const customArrowPreviewComponent = <div className='slider-navigation'><img src={chevron} alt="chevron"/></div>;
    const customArrowViewComponent = <div className='slider-navigation view'><img src={chevron} alt="chevron"/></div>;

    const updateList = () => {
        if(activeIndex + 3 <= works.length - 1){
            setActiveIndex(activeIndex + 3);
        } else {
            setActiveIndex(0);
        }
    }

    const properties = {
        prevArrow: customArrowPreviewComponent,
        nextArrow: customArrowPreviewComponent,
    }
    return (
        <>
            <Headline langKey={'our_works.title'} anchor='our_works'/>
            <section className="our-works">
                {isMobile ? (
                    <>
                        {mobileWorksList.map((src, index) => (
                            <div className="image-wrapper" key={ index }>
                                <img
                                    src={ src }
                                    alt={`work-${index + 1}`}
                                />
                            </div>
                        ))}
                        <div className="button-wrapper">
                            <button onClick={updateList}>{t('global.show_more')}</button>
                        </div>
                    </>
                ) : (
                    <>
                        <Slide autoplay={false} slidesToScroll={2} slidesToShow={2} indicators={false} responsive={getResponsiveSettings(480)} {...properties}>
                            {images.map((src, index) => (
                                <img
                                    src={ src }
                                    onClick={ () => openImageViewer(index) }
                                    key={ index }
                                    alt={`work-${index + 1}`}
                                />
                            ))}
                        </Slide>
                        {isViewerOpen && (
                            <ImageViewer
                                src={ images }
                                currentIndex={ currentImage }
                                disableScroll
                                closeOnClickOutside={ true }
                                onClose={ closeImageViewer }
                                closeComponent={<></>}
                                leftArrowComponent={customArrowViewComponent}
                                rightArrowComponent={customArrowViewComponent}
                            />
                        )}
                    </>
                )}
            </section>
        </>
    );
};
