import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Headline} from "../components/headline";
import chevron from '../assets/chevron-green.svg'

const serviceListOptions = [
    'brows',
    'face_sets',
    'make_up',
    'beauty_sets',
    'nails',
    'nails_care',
    'pedicure',
    'podologia',
    'lashes',
    'hair_do',
    'hair_cut',
]
const priceListOptions = {
    'brows': ['brows_jenny', 'brows_jenby'],
    'face_sets': [],
    'make_up': [],
    'beauty_sets': [],
    'nails': [],
    'nails_care': [],
    'pedicure': [],
    'podologia': [],
    'lashes': [],
    'hair_do': [],
    'hair_cut': [],
}

export const PriceList = () => {
    const {t} = useTranslation();
    const [selectedService, setSelectedService] = useState('');
    const [isShortList, setShortList] = useState(true);
    const normalizedServiceListOptions = isShortList ? serviceListOptions.slice(0, 5) : serviceListOptions
    const btnText = isShortList ? t('global.show_all_sections') : t('global.show_less_sections')
    const onPriceListItemSelect = (service) => {
        selectedService === service
            ? setSelectedService('')
            : setSelectedService(service);
    }

    return (
        <>
            <Headline langKey={'price_list.title'} anchor='price_list'/>
            <section className="price-list">
                <div className="price-list-inner col">
                    {normalizedServiceListOptions.map((service, index) => (
                        <div
                            key={index}
                            className={`price-list-item col flex-start ${selectedService === service ? "active" : ""}`}
                            onClick={() => onPriceListItemSelect(service)}
                        >
                            <div className="price-list-item-preview">
                                <div className="price-list-item-title">{t(`price_list.${service}.title`)}</div>
                                <img src={chevron} alt="chevron"/>
                            </div>
                            {priceListOptions[service] && <div className="price-list-item-services col">
                                {priceListOptions[service].map((subService, idx) => (
                                    <div className='price-list-item-service col' key={idx}>
                                        <div className='price-list-item-service-text-content space-between'>
                                            <div className='price-list-item-service-title'>{t(`price_list.${service}.${subService}.name`)}</div>
                                            <div className='price-list-item-service-price'>
                                                <b>{t(`price_list.${service}.${subService}.price`)}</b>
                                                <span>{t(`price_list.${service}.${subService}.currency_symbol`)}</span>
                                            </div>
                                        </div>
                                        <div className='price-list-item-service-description'>{t(`price_list.${service}.${subService}.description`)}</div>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    ))}
                    <button onClick={() => setShortList(!isShortList)}>{btnText}</button>
                </div>
            </section>
        </>
    )
}