import React, {memo, useState} from "react";
import mainImage from '../assets/main.jpg'
import {ServiceTicker} from "../components/service-ticker";
import {useTranslation} from "react-i18next";
export const AboutUs = memo(() => {
    const {t} = useTranslation();
    const [shortDescriptionMode, setShortDescriptionMode] = useState(true);
    const description = shortDescriptionMode ? t('main.club.description') : t('main.club.description_full');
    const btnText = shortDescriptionMode ? t('global.find_out_more') : t('global.show_less');

    return (
        <section id='about_us'>
            <div className="main-image">
                <img src={mainImage} alt="main"/>
            </div>
            <ServiceTicker/>
            <div className="about-information col">
                <div className="about-information-inner col">
                    <p className="about-information-text">{description}</p>
                    <p className="about-information-hashtag">{t('main.club.hashtag')}</p>
                    <button onClick={() => setShortDescriptionMode(!shortDescriptionMode)}>{btnText}</button>
                </div>
            </div>
        </section>
    )
})