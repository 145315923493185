export const getResponsiveSettings = (imageWidth) => [...Array(10)].map((_, index) => ({
    breakpoint: index === 0 ? 1 : index * imageWidth,
    settings: {
        slidesToShow: index === 0 ? 1 : index + 1,
        slidesToScroll: 1
    }
})).reverse();

export function debounce(func, wait) {
    let timeout;
    return function() {
        let context = this, args = arguments;
        let later = function() {
            timeout = null;
            func.apply(context, args);
        };
        let callNow = !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}