import React, {useContext} from "react";
import {ModeContext, ModeEnum} from "../context/mode-context";
export const ModeToggler = () => {
    const {mode, setMode} = useContext(ModeContext);

    return (
            <div className="mode-togglers-container">
                <div
                    className={`mode-toggler row ${mode === ModeEnum.Club && 'active'}`}
                    onClick={() => setMode(ModeEnum.Club)}
                >
                    {ModeEnum.Club}
                </div>
                <div
                    className={`mode-toggler row ${mode === ModeEnum.Clinic && 'active'}`}
                    onClick={() => setMode(ModeEnum.Clinic)}
                >
                    {ModeEnum.Clinic}
                </div>
            </div>
    )
}