import React from "react";
import partners1 from '../assets/partners_1.png';
import partners2 from '../assets/partners_2.png';
import partnersMob from '../assets/partners_mob.jpg';

export const Partners = () => (
    <div className='partners col'>
        <img src={partners1} alt="partners-1"/>
        <img src={partners2} alt="partners-2"/>
        <img className='partners-mob' src={partnersMob} alt="partners-all"/>
    </div>
)