import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import serviceImagePreview from '../assets/services.jpg'
import {Headline} from "../components/headline";
import {ResizeContext} from "../context/resize-context";

const servicesOptions = [
    'hair_services',
    'make-up_and_treatments_for_eyebrows_and_eyelashes',
    'nail_aesthetics',
    'permanent_makeup',
    'aesthetic_cosmetology',
    'injection_cosmetology',
    'hardware_cosmetology'
]

export const Services = () => {
    const {isMobile} = useContext(ResizeContext);
    const initialSelectedService = !isMobile  ? servicesOptions[0] : '';
    const {t} = useTranslation();
    const [selectedService, setSelectedService] = useState(initialSelectedService);
    const address1 = t(`services.${selectedService}.address1`);
    const address2 = t(`services.${selectedService}.address2`);

    useEffect(() => {
        setSelectedService(!isMobile  ? servicesOptions[0] : '');
    }, [isMobile])

    return (
        <>
            <Headline langKey={'services.title'} anchor='services'/>
            <section className="services">
                <div className="image-preview col">
                    <img src={serviceImagePreview} alt="service-preview"/>
                </div>
                <div className={`services-navigation ${selectedService === servicesOptions[0] ? 'selected-top' : ''} ${selectedService === servicesOptions[servicesOptions.length - 1] ? 'selected-bottom' : ''}`}>
                    {servicesOptions.map((service, idx) => (
                            <div
                                key={idx}
                                className={`services-navigation-item col flex-start ${selectedService === service ? 'active' : ''}`}
                                onClick={() => setSelectedService(service)}
                            >
                                <div className='services-navigation-item-title'>{t(`services.${service}.title`)}</div>
                                <div className="services-navigation-item-content col flex-start">
                                    <div className="services-navigation-item-content-description">{t(`services.${selectedService}.description`)}</div>
                                    <div className="services-navigation-item-content-addresses">
                                        {address1 && <p className="services-navigation-item-content-address">{address1}</p>}
                                        {address2 && <p className="services-navigation-item-content-address">{address2}</p>}
                                    </div>
                                    <button>{t(`global.book_visit`)}</button>
                                </div>
                            </div>
                    ))}
                </div>
                <div className="services-content col flex-start">
                    <div className="services-content-description">{t(`services.${selectedService}.description`)}</div>
                    <div className="services-content-addresses">
                        {address1 && <p className="services-content-address">{address1}</p>}
                        {address2 && <p className="services-content-address">{address2}</p>}
                    </div>
                    <button>{t(`global.book_visit`)}</button>
                </div>
            </section>
        </>
    )
}