import React from "react";
import {useTranslation} from "react-i18next";

export const LanguageEnum = {
    EN: 'en',
    PL: 'pl'
}

export const LanguageSelector = () => {
    const { i18n } = useTranslation();
    return (
      <div className="language-togglers-container row">
          <div
              className={`mode-toggler row ${i18n.resolvedLanguage === LanguageEnum.EN ? 'active' : ''}`}
              onClick={() => i18n.changeLanguage('en')}
          >
              {LanguageEnum.EN}
          </div>
          <div
              className={`mode-toggler row ${i18n.resolvedLanguage === LanguageEnum.PL ? 'active' : ''}`}
              onClick={() => i18n.changeLanguage(LanguageEnum.PL)}
          >
              {LanguageEnum.PL}
          </div>
      </div>
    )
}