import React, {useRef} from "react";
import {ModeToggler} from "./mode-toggler";
import logo from '../assets/logo.svg';
import instagramLogo from '../assets/instagram_logo_yellow.svg';
import {Navigation} from "./navigation";
import {useTranslation} from "react-i18next";
import {LanguageSelector} from "./language-selector";
import {useIntersectObserve} from "../hooks";

export const Header = () => {
    const { t } = useTranslation();
    const headerRef = useRef();
    const entry = useIntersectObserve(headerRef);
    const isVisible = !!entry?.isIntersecting;

    return (
        <header ref={headerRef}>
            <div className={`header-inner ${isVisible ? '' : 'simplify'}`}>
                <div className='hide-content row'>
                    <img className="logo" src={logo} alt="logo"/>
                    <ModeToggler/>
                    <LanguageSelector/>
                </div>
                <div className="content row space-between">
                    <img className="logo" src={logo} alt="logo"/>
                    <a  className='insta_link' href="https://www.instagram.com/leobeautyclub.warsaw/" target="_blank"  rel="noreferrer">
                        <img src={instagramLogo} alt="instagram-logo"/>
                    </a>
                    <Navigation />
                    <a className='phone_link' href={`tel:${t('main.club.phone')}`}  rel="noreferrer">Tel: {t('main.club.phone')}</a>
                    <div className='hide-content row'>
                        <ModeToggler/>
                    </div>
                </div>
            </div>
            <div className={`reserve-now-btn row ${isVisible ? "" : "hidden"}`}>{t('global.reserve_online')}</div>
        </header>
    )
}