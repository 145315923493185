import React, {useContext} from 'react';
import {ModeContext, ModeEnum} from "../../context/mode-context";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export const PrePage = () => {
    const {setMode} = useContext(ModeContext);
    const navigate = useNavigate();

    const { t } = useTranslation();
    const onClinicBtnClick = () => {
        setMode(ModeEnum.Clinic);
        navigate(`/${ModeEnum.Clinic}`);
    }
    const onClubBtnClick = () => {
        setMode(ModeEnum.Club);
        navigate(`/${ModeEnum.Club}`);
    }
    return (
        <div className='prepage-container row'>
            <div className="prepage-part col">
                <div className="prepage-part-title">{t('main.club.title')}</div>
                <div className="prepage-part-adress">{t('main.club.address')}</div>
                <div className="prepage-part-phone">{t('main.club.phone')}</div>
                <button className="prepage-part-btn" onClick={onClubBtnClick}>{t('global.website')}</button>
            </div>
            <div className="prepage-part col">
                <div className="prepage-part-title">{t('main.clinic.title')}</div>
                <div className="prepage-part-adress">{t('main.clinic.address')}</div>
                <div className="prepage-part-phone">{t('main.clinic.phone')}</div>
                <button className="prepage-part-btn" onClick={onClinicBtnClick}>{t('global.website')}</button>
            </div>
        </div>
    )
}