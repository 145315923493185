import React, {createContext, useLayoutEffect, useState} from 'react';

const resizeInitial = {
    width: window.innerWidth,
    height: window.innerHeight,
}
export const ResizeContext = createContext({
    ...resizeInitial,
    isMobile: false
});

export const ResizeContextProvider = ({ children }) => {
    const [size, setSize] = useState(resizeInitial);
    const [isMobile, setMobile] = useState(false);
    useLayoutEffect(() => {
        function updateSize() {
            setSize({width: window.innerWidth, height: window.innerHeight});
            setMobile(window.innerWidth < 991);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const values = { ...size, isMobile }

    return <ResizeContext.Provider value={values}>{children}</ResizeContext.Provider>;
};
