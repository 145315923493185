import React from 'react';
import {useTranslation} from "react-i18next";
import {Headline} from "../components/headline";
import clubPreview from '../assets/club_preview.jpg';
import clinicPreview from '../assets/clinic_preview.jpg';

export const Addresses = () => {
    const { t } = useTranslation();

    return (
        <>
            <Headline langKey={'addresses.title'} anchor='contacts'/>
            <section className="addresses">
                <div className='prepage-container row'>
                    <div className="prepage-part club col">
                        <div className="prepage-part-text col">
                            <div className="prepage-part-title">{t('main.club.title')}</div>
                            <div className="prepage-part-adress">{t('main.club.address')}</div>
                            <div className="prepage-part-phone">{t('main.club.phone')}</div>
                        </div>
                        <button className="prepage-part-btn">
                            <a href="https://www.google.com/maps/place/Ogrodowa+58,+00-876+Warszawa,+Poland/" target='_blank'  rel="noreferrer">
                                {t('global.see_on_the_map')}
                            </a>
                        </button>
                    </div>
                    <img className='club-preview' src={clubPreview} alt="club-preview"/>
                    <div className="prepage-part clinic col">
                        <div className="prepage-part-text col">
                            <div className="prepage-part-title">{t('main.clinic.title')}</div>
                            <div className="prepage-part-adress">{t('main.clinic.address')}</div>
                            <div className="prepage-part-phone">{t('main.clinic.phone')}</div>
                        </div>
                        <button className="prepage-part-btn">
                            <a href="https://www.google.com/maps/place/Stawki+4B,+00-189+Warszawa,+Poland/" target='_blank'  rel="noreferrer">
                                {t('global.see_on_the_map')}
                            </a>
                        </button>
                    </div>
                    <img className='clinic-preview' src={clinicPreview} alt="club-preview"/>
                </div>
            </section>
        </>
    )
}