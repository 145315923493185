import React, { createContext, useEffect, useState } from 'react';
import {noop} from "../constants";

export const ModeEnum = {
    Clinic: 'clinic',
    Club: 'club'
}

export const ModeContext = createContext({
    mode: '',
    setMode: noop
});

export const ModeContextProvider = ({ children }) => {
    const [mode, setMode] = useState('');
    const setModeGlobal = (modeValue) => {
        localStorage.setItem("mode", modeValue);
        setMode(modeValue);
    }

    useEffect(() => {
        const mode = localStorage.getItem("mode");
        setMode(mode ?? ModeEnum.Club);
        return () => setModeGlobal(ModeEnum.Club);
    }, []);

    const values = { mode, setMode: setModeGlobal }

    return <ModeContext.Provider value={values}>{children}</ModeContext.Provider>;
};
