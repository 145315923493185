import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import hamburger from "../assets/hamburger.svg";
import close from "../assets/close.svg";

export const Navigation = () => {
    const [hiddenNavList, setHiddenNavList] = useState(true);
    const { t} = useTranslation();

    const navigationList = [
        { title: t("global.about_us"), anchor: '#about_us'},
        { title: t("global.services"), anchor: '#services'},
        { title: t("global.price_list"), anchor: '#price_list'},
        { title: t("global.our_works"), anchor: '#our_works'},
        { title: t("global.specialists"), anchor: '#specialists'},
        { title: t("global.contacts"), anchor: '#contacts'},
    ]
  return (
      <>
          <div className="hamburger" onClick={() => setHiddenNavList(!hiddenNavList)}>
              <img src={hamburger} alt="hamburger"/>
          </div>

          <nav className={`navigation-list row ${hiddenNavList ? 'hidden' : ''}`}>
              {navigationList.map(({title, anchor}, idx) => (
                  <a key={idx} href={anchor} className='navigation-list-item' onClick={() => setHiddenNavList(true)}>{title}</a>
              ))}
              <div className="close" onClick={() => setHiddenNavList(!hiddenNavList)}>
                  <img src={close} alt="close"/>
              </div>
          </nav>
      </>

  )
}