import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {PrePage} from "./pages/prepage/prepage";
import {Club} from "./pages/club/club";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Clinic} from "./pages/clinic/clinic";
import {NotFound} from "./pages/not-found/not-found";
import {ResizeContextProvider} from "./context/resize-context";
import {ModeContextProvider} from "./context/mode-context";
import './i18n';
import './styles/common.css'

const router = createBrowserRouter([
    {
        path: "/",
        element: <PrePage/>,
    },
    {
        path: "/club",
        element: <Club/>,
    },
    {
        path: "/clinic",
        element: <Clinic/>,
    },
    {
        path: "*",
        element: <NotFound/>,
    },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ResizeContextProvider>
          <ModeContextProvider>
              <RouterProvider router={router}/>
          </ModeContextProvider>
      </ResizeContextProvider>
  </React.StrictMode>
);

reportWebVitals();
