import React, {useCallback, useContext, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Headline} from "../components/headline";
import {Slide} from 'react-slideshow-image';
import {ResizeContext} from "../context/resize-context";
import {getResponsiveSettings} from "../utils";
import {useMutationObservable} from "../hooks";
import chevron from '../assets/chevron-white.svg'
import chevronGreen from '../assets/chevron-green.svg';
import hairServiceEmployee from '../assets/hair_servise_employee.jpg';
import makeUpEmployee from '../assets/make_up_employee.jpg';
import nailsAestheticsEmployee from '../assets/nails_aesthetics_employee.jpg';
import permanentMakeUpEmployee from '../assets/permanent_make_up_employee.jpg';
import aestheticCosmetologyEmployee from '../assets/aesthetic_cosmetology_employee.jpg';
import injectionCosmetologyEmployee from '../assets/injection_cosmetology_employee.jpg';
import 'react-slideshow-image/dist/styles.css';

export const OurTeam = () => {
    const slideRef = useRef();
    const componentRef = useRef();
    const {t} = useTranslation();
    const {width, isMobile} = useContext(ResizeContext);
    const [isDepartmentsListShow, setDepartmentsListShow] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const employees = [
        {
            id: 'hair_services',
            image: hairServiceEmployee,
            title: t('our_team.departments.hair_services.title'),
        },
        {
            id: 'make_up',
            image: makeUpEmployee,
            title: t('our_team.departments.make_up.title'),
        },
        {
            id: 'nail_aesthetics',
            image: nailsAestheticsEmployee,
            title: t('our_team.departments.nail_aesthetics.title'),
        },
        {
            id: 'permanent_makeup',
            image: permanentMakeUpEmployee,
            title: t('our_team.departments.permanent_makeup.title'),
        },
        {
            id: 'aesthetic_cosmetology',
            image: aestheticCosmetologyEmployee,
            title: t('our_team.departments.aesthetic_cosmetology.title'),
        },
        {
            id: 'injection_cosmetology',
            image: injectionCosmetologyEmployee,
            title: t('our_team.departments.injection_cosmetology.title'),
        },
    ];
    const employeesList = width > employees.length * 360 ? [...employees, ...employees] : employees;

    const customArrowPreviewComponent = <div className='slider-navigation'><img src={chevron} alt="chevron"/></div>;

    const properties = {
        prevArrow: customArrowPreviewComponent,
        nextArrow: customArrowPreviewComponent,
    }
    const onListMutation = useCallback(
        (mutationList) => {
            const activeIndicator = mutationList.find(mutation => mutation?.target?.classList?.contains('departments-indicator') && mutation.target.classList.contains('active')) ;
            const activeIndex = activeIndicator?.target?.dataset?.key;
            if(activeIndex){
                setActiveIndex(Number(activeIndex))
            }
        },
        [setActiveIndex]
    );
    useMutationObservable(componentRef.current, onListMutation);

    const indicators = (index) => (
        <div className='departments-indicator'>
            {employees[index].title}
        </div>
    );

    return (
        <>
            <Headline langKey={'our_team.title'} anchor='specialists'/>
            <section className="our-team" ref={componentRef}>
                {isMobile ? (
                    <>
                        <div className={`departments-container ${isDepartmentsListShow ? "" : 'hidden'}`} onClick={() => setDepartmentsListShow(!isDepartmentsListShow)}>
                            <div className="selected-department">
                                <span>{employees[activeIndex].title}</span>
                                <img src={chevronGreen} alt="chevron"/>
                            </div>
                            <div className="departments-list">
                                {employees.map(({id, title}, index) => (
                                    <div
                                        onClick={() => setActiveIndex(index)}
                                        className={`departments-list-item ${activeIndex === index ? 'active' : ''}`}
                                        key={index}
                                    >
                                        {title}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="department-employee">
                            <img src={employees[activeIndex].image} alt="department-employee"/>
                        </div>
                    </>
                ) : (
                    <Slide indicators={indicators} ref={slideRef} autoplay={false} responsive={getResponsiveSettings(360)} {...properties}>
                        {employeesList.map(({id, title, image}, index) => {
                            return (
                            <div className='our-team-employee' key={index} data-idx={index}>
                                <div className={`active-department-title ${index === activeIndex ? "active" : ""}`}>{t(employees[index].title)}</div>
                                <img src={image} alt={`employee-${index + 1}`}/>
                            </div>
                        )})}
                    </Slide>
                )}
            </section>
        </>
    );
};
