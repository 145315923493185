import React, {memo} from "react";
import ticker from "../assets/ticker.png"
import Marquee from "react-fast-marquee";

export const ServiceTicker = memo(() => (
    <div className="service-ticker">
        <Marquee>
            <img src={ticker} alt="ticker"/>
        </Marquee>
    </div>
));

