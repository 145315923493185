import React from 'react';
import {useTranslation} from "react-i18next";
import {Page} from "../../components/page";
import errorText from "../../assets/404.svg"

export const NotFound = () => {
    const {t} = useTranslation();
    return (
        <Page>
            <div className='page-not-found col'>
                <img src={errorText} alt="errorText"/>
                <p>{t('global.page_not_found')}</p>
            </div>
        </Page>
    )
}