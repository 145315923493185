import React from "react";
import {useTranslation} from "react-i18next";

export const Headline = ({langKey, anchor}) => {
    const {t} = useTranslation();

    return (
        <section id={anchor} className='headline'>
            <div className="dash" />
            <span>{t(langKey)}</span>
            <div className="dash" />
        </section>
    )
}